<template>
  <b-modal id="ModalRetencaoContaB" title="Desconto" size="lg" variant="dark" :visible="showModal" :hide-footer="true"
    @hide="hideModal">
    <div v-if="antecipacao">
      <div v-if="url">

        <div class="alert alert-warning mb-4">
          {{ message }}
        </div>
        <h5>Aqui está o link para pagamento!</h5>
        <div>
          <b-form-input :value="url" id="myInput" type="text" readonly style="font-size:14px;"></b-form-input>
        </div>
        <div>
          <b-button class="mt-4 mb-2" block @click.prevent="copiarLink()" variant="primary">Copiar link</b-button>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning mb-4">
          Aviso! Essa assinatura será migrada para compra parcelada.
        </div>

        <div>
          <div v-if="!seguro.desconto" class="alert alert-info mb-4">
            Aplique o valor do desconto em percentual.
          </div>
          <div v-else class="alert alert-info mb-4">
            Esse seguro já possui desconto de {{ seguro.percentual }}% e somente
            será migrado. Seu valor sem o desconto é :
            {{ formatacaoMoeda3(seguro.valorAnterior) }}.
          </div>

          <div v-if="!seguro.desconto" class="my-2">
            <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1">
              Desejo aplicar desconto.
            </b-form-checkbox>
          </div>
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(aplicar)">
              <div v-if="status">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Valor do desconto (%)</label>
                      <ValidationProvider name="valor do desconto" rules="required" v-slot="{ errors, classes }">
                        <input type="number" class="form-control" v-model="valorDesconto" :class="classes"
                          placeholder="Valor do desconto" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Data fim do desconto</label>
                      <ValidationProvider name="Data fim do desconto" rules="required|data_fim_desconto"
                        v-slot="{ errors, classes }">
                        <input type="tel" class="form-control" v-model="dataFimDesconto" :class="classes"
                          v-mask="'##/##/####'" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Valor final do seguro</label>
                    <h2>
                      {{
                        valorDesconto
                          ? formatacaoMoeda3(
                            valorSeguro - (valorSeguro * valorDesconto) / 100
                          )
                          : formatacaoMoeda3(valorSeguro)
                      }}
                    </h2>
                  </div>
                </div>
              </div>

              <hr class="mt-3 mb-3" />
              <div class="row">
                <div class="col-6">
                  <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
                    <span v-if="loading">Aplicando migração...</span>
                    <span v-else>Aplicar migração</span>
                  </button>
                </div>
                <div class="col-6">
                  <button class="btn btn-danger btn-block" @click.prevent="hideModal()" :disabled="loading">
                    <span>Cancelar</span>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card-mensagem mb-4 selected" @click.prevent="antecipacao = true;">
        <h5>Quero antecipar um pagamento</h5>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import auth from "../../auth";
import config from "../../config";
import axios from "axios";
import formatacaoData from "../../helpers/formatacaoData";
import captureError from "../../helpers/captureError";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import moment from "moment";
export default {
  props: ["seguro", "showModal"],
  components: {},
  data() {
    return {
      retencao: false,
      antecipacao: false,
      estorno: false,
      formatacaoData,
      formatacaoMoeda3,
      config,
      loading: false,
      valorDesconto: undefined,
      dataFimDesconto: undefined,
      valorSeguro: 0,
      modificou: false,
      url: "",
      message: "",
      status: false,
    };
  },

  watch: {
    showModal: function (newValue, oldValue) {
      this.url = "";
      this.message = "";
      this.status = false;
      if (newValue != oldValue && newValue) {
        if (this.seguro.desconto) {
          this.status = !this.seguro.desconto;
        }

        this.definirValorSeguro();

      }
      if (newValue != oldValue && !newValue && this.modificou) {
        this.hideModal();
      }
    },
    valorDesconto: function (newValue, oldValue) {
      if (newValue != oldValue && typeof newValue === "string") {
        this.valorDesconto = newValue.replace(/[^0-9]/g, "");
      }
      if (newValue != oldValue && newValue > 100) {
        return (this.valorDesconto = 100);
      }
    },
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.dataFimDesconto = undefined;
      this.valorDesconto = undefined;
      this.retencao = false;
      this.antecipacao = false;
      this.estorno = false;
    },
    definirValorSeguro() {
      return (this.valorSeguro = this.seguro.valorPlano);
    },
    copiarLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O link de pagamento foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
    async aplicar() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente antecipar este pagamento?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        let dataFim = undefined;
        if (this.dataFimDesconto) {
          dataFim = moment(this.dataFimDesconto, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        }

        const data = {
          idSeguro: this.seguro.id,
          valorDesconto: this.valorDesconto,
          idUsuarioAdm: userInfo.id,
          dataFimDesconto: dataFim,
          desconto: this.seguro.desconto
        };

        var response = null;

        if (this.antecipacao) {
          response = await axios.post(
            `${config.API_URLV2}/pagamentoSeguro/atencipacaoContaB`,
            data
          );
        }
        
        this.message = response.data.message;
        
        this.url = response.data.url;

        this.loading = false;
        this.valorDesconto = undefined;
        this.modificou = true;
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel antecipar o seguro");
      }
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #4FA935 !important;
}

.card-mensagem {
  cursor: pointer;
  text-align: center;
  color: #FFFFFF;
  padding: 24px;
  background-color: #78849E;
  border-radius: 12px;
}
</style>